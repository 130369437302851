export default class CookieInterface {
    /**
     * @param name
     * @param value
     * @param days
     * @param domain
     */
    static createCookie(name, value, days, domain = `.${window.location.host}`) {
        let expires = '';

        if (days) {
            let date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));

            expires = "expires=" + date.toUTCString();
        }

        document.cookie = `${name}=${value};${expires};domain=${domain};path=/`;
    }

    /**
     * @param name
     * @returns {string|null}
     */
    static readCookie(name) {
        let nameEQ = name + "=";
        let ca = document.cookie.split(';');

        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }

        return null;
    }

    /**
     *
     * @param name
     */
    static eraseCookie(name) {
        CookieInterface.createCookie(name, "", -1);
    }
}