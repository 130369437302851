export default {
    onInit: function () {
        let maxLength = $(this).attr('maxlength');

        if (maxLength === "") {
            return;
        }

        moveLettersCounter(this, this.clientHeight < this.scrollHeight);
        updateLettersCounter(this, this.value, maxLength);
    },
    onKeydown: function (e) {
        let maxLength = Number($(this).attr('maxlength')),
            value = e.currentTarget.value;
        if (maxLength === 0) {
            return;
        }

        if (isWysiwygTextarea(this)) {
            value = $('<textarea />').html($(this).summernote('code')).text().replace(/(<([^>]+)>)/ig).trim();
        }

        if (value.length >= maxLength) {
            if (
                e.keyCode !== 8
                && !(e.keyCode >= 37 && e.keyCode <= 40)
                && e.keyCode !== 46
                && !(e.keyCode === 88 && e.ctrlKey)
                && !(e.keyCode === 67 && e.ctrlKey)
                && !(e.keyCode === 65 && e.ctrlKey)
            ) {
                e.preventDefault();
            }
        }
        updateLettersCounter(this, this.value, maxLength);

    },
    onKeyup: function (e) {
        let maxLength = Number($(this).attr('maxlength')),
            value = e.currentTarget.value;

        if (maxLength === 0) {
            return;
        }

        if (isWysiwygTextarea(this)) {
            value = $('<textarea />').html($(this).summernote('code')).text().replace(/(<([^>]+)>)/ig,"").trim();

            let range = $(this).summernote('editor.getLastRange');  // получение последнего положения курсора
            const {sc, so} = range;
            let textContent;
            let textNode;
            if (sc.nodeType == 3) {
                textContent = sc.textContent;
                textNode = sc;
            } else if (sc.nodeType == 1){
                if (sc.data) {
                    textContent = sc.data.textContent;
                    textNode = sc.data;
                }
            }
            if (/\s{2,}/.test(textContent)) {
                replaceSpaces(textNode, textContent, so)
            }
        }

        moveLettersCounter(this, this.clientHeight < this.scrollHeight);
        updateLettersCounter(this, value, maxLength);
        $(this).valid();
    },
    onPaste: function (e) {
        let maxLength = Number($(this).attr('maxlength')),
            value = e.currentTarget.value,
            bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text');

        if (maxLength === 0) {
            return;
        }

        if (isWysiwygTextarea(this)) {
            value = $('<textarea />').html($(this).summernote('code')).text().replace(/(<([^>]+)>)/ig,"").trim();
        }

        moveLettersCounter(this, this.clientHeight < this.scrollHeight);
        e.preventDefault();

        let maxPaste = maxLength;
        if (value.length + bufferText.length > maxLength) {
            maxPaste = maxLength - value.length;
        }

        if (maxPaste > 0) {
            // Firefox fix
            setTimeout(() => {
                let pasteData = bufferText.substring(0, maxPaste)
                document.execCommand('insertText', false, pasteData);
                updateLettersCounter(this, isWysiwygTextarea(this) ? e.currentTarget.innerText : e.currentTarget.value, maxLength);
            }, 10);
        }

        $(this).valid();
    }
};

/**
 *
 * @param self
 * @returns {boolean}
 */
function isWysiwygTextarea(self) {
    let isWysiwyg = false;

    if (self.classList.contains('js-wysiwyg-text-editor')) {
        isWysiwyg = true;
    }

    return isWysiwyg;
}

/**
 * @param self
 * @param value
 * @param maxLength
 */
function updateLettersCounter(self, value, maxLength) {
    let $element = $(self),
        $lettersCounter = $element.closest('.form__group').find('.js-letters-counter'),
        characters = value;
    let regexp = /(&nbsp;)/g

    if (isWysiwygTextarea(self)) {
        characters = $('<textarea />').html($(self).summernote('code')).text().replace(/(<([^>]+)>)/ig,"").trim();
    }
    let length = characters.length;

    length = (characters.length > 0 && characters[characters.length - 1].trim() == "") ? length - 1 : length;

    $lettersCounter.text(length + " / " + maxLength);
}

/**
 * @param self
 * @param add
 */
function moveLettersCounter(self, add) {
    if (isWysiwygTextarea(self)) return;

    const $counter = $(self)
        .closest('.form__group')
        .find('.js-letters-counter');

    if (add) {
        $counter.addClass('form__input_textarea-counter-with-scroll');
    } else {
        $counter.removeClass('form__input_textarea-counter-with-scroll');
    }
}

function replaceSpaces (textNode, textContent, pos) {
    textNode.textContent = textContent.replace(/\s{2,}/, ' ');
    setCaretPos(textNode, pos - 1)
}

function setCaretPos(textNode, position) {
    if(textNode) {
        let newPosition;
        if (position > textNode.length) {
            newPosition = textNode.length
        } else {
            newPosition = position
        }
        let parent = textNode.parentNode;
        parent.focus();
        let range = document.createRange();
        range.setStart(textNode, newPosition);
        range.setEnd(textNode, newPosition);
        let sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
    }
}