import CodeMirror from 'codemirror/lib/codemirror';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/xml/xml';
import 'codemirror/theme/idea.css'

import 'summernote/dist/summernote-bs4';
import 'summernote/dist/summernote-bs4.css';

import './summernote-ru-RU';

import Callbacks from './MaxLengthCallbacks';

import _ from 'lodash';

(function ($) {
    $.fn.summernoteEditor = function (options) {
        const settings = $.extend({
            summernoteSettings: {
                height: 150,
                toolbar: [
                    ['font', ['bold', 'underline']],
                    ['para', ['ul', 'ol']],
                    ['link', ['link']],
                ],
                lang: 'ru-RU',
            },
        }, options);

        this.each(function () {
            const clonedSettings = _.cloneDeep(settings);
            new Summernote(this, clonedSettings);
        });
    };
})(jQuery);

class Summernote {
    constructor(element, settings) {
        this._element = element;
        this._settings = settings;

        this.init();
    }

    init() {
        const $element = $(this._element);

        if (typeof $element.attr('allow_html') !== "undefined") {
            this._settings.summernoteSettings = _.merge(this._settings.summernoteSettings, {
                codemirror: {
                    CodeMirrorConstructor: CodeMirror,
                    theme: 'idea',
                    lineNumbers: true,
                }
            });

            this.addToolBarCodeView();
        } else {
            this.addOnPasteCleaner();
        }

        if (typeof $element.attr('allow_photo_video') !== "undefined") {
            this.addToolBarPhotoVideoView();
        }

        if (typeof $element.attr('allow_font_style') !== "undefined") {
            this.addToolBarFontStyleView();
        }

        if (typeof $element.attr('allow_font_family') !== "undefined") {
            this.addToolBarFontFamilyView();
        }

        if (typeof $element.attr('allow_font_color') !== "undefined") {
            this.addToolBarFontColorView();
        }

        if (typeof $element.attr('allow_table') !== "undefined") {
            this.addToolBarTableView();
        }

        if ($element.attr('maxlength') !== "") {
            this.addLettersCounter();
        }

        $element.summernote(this._settings.summernoteSettings);
    }

    addToolBarCodeView() {
        this._settings.summernoteSettings.toolbar.push(
            ['view', ['codeview']]
        );
    }

    addToolBarPhotoVideoView() {
        this._settings.summernoteSettings.toolbar.push(
            ['insert', ['picture', 'video']]
        );
    }

    addToolBarFontStyleView() {
        this._settings.summernoteSettings.toolbar.push(
            ['style', ['style']]
        );
    }

    addToolBarFontFamilyView() {
        this._settings.summernoteSettings.toolbar.push(
            ['fontname', ['fontname']]
        );
    }

    addToolBarFontColorView() {
        this._settings.summernoteSettings.toolbar.push(
            ['color', ['color']]
        );
    }

    addToolBarTableView() {
        this._settings.summernoteSettings.toolbar.push(
            ['table', ['table']]
        );
    }

    addOnPasteCleaner() {
        this._settings.summernoteSettings.callbacks = {
            onPaste: function (e) {
                let bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text');

                e.preventDefault();

                // Firefox fix
                setTimeout(function () {
                    document.execCommand('insertText', false, bufferText);
                }, 10);
            }
        }
    }

    addLettersCounter() {
        if (typeof this._settings.summernoteSettings.callbacks === "undefined") {
            this._settings.summernoteSettings['callbacks'] = {};
        }

        this._settings.summernoteSettings.callbacks['onInit'] = Callbacks.onInit;
        this._settings.summernoteSettings.callbacks['onKeydown'] = Callbacks.onKeydown;
        this._settings.summernoteSettings.callbacks['onKeyup'] = Callbacks.onKeyup;
        this._settings.summernoteSettings.callbacks['onPaste'] = Callbacks.onPaste;
        this._settings.summernoteSettings.callbacks['onBlurCodeview'] = function () {
            if ($(this).summernote('codeview.isActivated')) {
                $(this).val($(this).summernote('code'));
                $(this).validate();
            }

            return true;
        };
    }
}